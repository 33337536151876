<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="widget">
        <div class="widget-footer with-tabs bb-1">
          <ul class="nav nav-tabs nav-tabs-line" role="tablist">
            <v-guard :permissions="['member-section-basics']">
              <li :class="{ 'current-section': showDetailSection === 1 }" class="nav-item section">
                <div @click="setShowDetailSection(1)" class="nav-link">{{ $t('labels.basic-information') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['member-section-assignment']">
              <li :class="{ 'current-section': showDetailSection === 2 }" class="nav-item section">
                <div @click="setShowDetailSection(2)" class="nav-link">{{ $t('labels.assignment') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['member-section-merits-view']">
              <li :class="{ 'current-section': showDetailSection === 3 }" class="nav-item section">
                <div @click="setShowDetailSection(3)" class="nav-link">{{ $t('labels.merits') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['member-delete-member']">
              <li :class="{ 'current-section': showDetailSection === 4 }" class="nav-item section ml-auto mr-0">
                <div class="d-flex justify-content-between">
                  <div @click="$modal.show('Medlemsformulär  - Radera medlem-knapp'); setShowDetailSection(4);" class="nav-link">{{ $t('labels.delete-member') }}</div><info-box style="display: inline-flex;" :field="'Medlemsformulär  - Radera medlem-knapp'" />
                </div>
              </li>
            </v-guard>
          </ul>
        </div>
        <div class="widget-body" v-if="!isLoading">
          <div class="row" v-show="showDetailSection == 1">
            <div class="col-4 br-1">
              <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.name-member-type') }}</h5>

              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" @updateMember="updateMember" field="first_name" :label="this.$t('labels.first-name')"
                  type="text"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" @updateMember="updateMember" field="last_name" :label="this.$t('labels.last-name')"
                  type="text"></text-field>

              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :clearable="false"
                  :model="member"
                  :multiple="false"
                  :options="memberFieldOptions.genders"
                  @updateMember="updateMember"
                  db_field="member_gender_id"
                  field="gender"
                  :label="this.$t('labels.gender')"
              ></select-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" @updateMember="updateMember" field="birth_year" :label="this.$t('labels.birth-year')"
                  type="text"></text-field>
              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="false"
                  :options="memberFieldOptions.legal_people"
                  @updateMember="updateMember"
                  db_field="member_legal_person_id"
                  field="legal"
                  :label="this.$t('labels.type-legal-person')"
                  :optionLabel="this.$label()"
              ></select-field>
              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-category-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="false"
                  :options="memberFieldOptions.categories"
                  @updateMember="updateMember"
                  db_field="member_category_id"
                  field="category"
                  :label="this.$t('labels.category')"
                  :optionLabel="this.$label()"
              ></select-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :infoBox="'Medlemsformulär  - Namn&Medlemstyp, Antal tidningar'"
                  :model="member" field="number_newspaper" :label="this.$t('labels.number-of-newspapers')" type="number"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="member_connection" :label="this.$t('labels.member-connection')"></text-field>
            </div>
            <div class="col-4 br-1">
              <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.contact-information') }}</h5>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :infoBox="'Medlemsformulär  - Kontaktuppgifter, E-post 1'"
                  :model="member" field="email" :label="this.$t('labels.email-one')" type="email"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="email_2" :label="this.$t('labels.email-two')" type="email"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="phone_1" :label="this.$t('labels.phone-one')" type="tel"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="phone_2" :label="this.$t('labels.phone-two')" type="tel"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="mobile" :label="this.$t('labels.mobile-private')" type="tel"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :infoBox="'Medlemsformulär  - Kontaktuppgifter, Tjänstemobil'"
                  :model="member" field="mobile_office" :label="this.$t('labels.mobile-business')" type="tel"></text-field>
              <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.notes') }}<info-box :field="'Medlemsformulär  - Kontaktuppgifter, Anteckningar'" /></h5>
              <textarea-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-notes-edit')"
                  :model="member" field="comments" :label="this.$t('labels.comment')" type="text"></textarea-field>
            </div>
            <div class="col-4">
              <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.address') }}</h5>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="address_1" :label="this.$t('labels.address-one')" type="text"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="address_2" :label="this.$t('labels.address-two')" type="text"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="postal_code" :label="this.$t('labels.postal-code')" type="text"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="city" :label="this.$t('labels.postal-area')" type="text"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="country" :label="this.$t('labels.country')" type="text"></text-field>
              <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.other') }}</h5>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-signup-edit')"
                  :model="member" field="member_since" :label="this.$t('labels.enrolled')" type="date"></text-field>
              <!--
                /*
                * Hide for now
                */

                <checkbox-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-other-edit')"
                  :model="member" field="is_not_member" label="Ej medlem"></checkbox-field> -->
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-basics-fee-edit')"
                  :model="member" field="paid_fees" :label="this.$t('labels.membership-fee-paid-at')" type="date"></text-field>
            </div>
          </div>
          <div class="row" v-show="showDetailSection == 2">
            <div class="col-4 br-1">
              <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.local-assignments') }}<info-box :field="'Medlemsformulär  - Uppdrag, Lokala uppdrag rubrik'" /></h5>

              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-local-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="false"
                  :options="_.orderBy(memberFieldOptions.board_assignments, [$field('label')])"
                  @updateMember="updateMember"
                  db_field="local_member_board_assignments_id"
                  field="local_board_assignment"
                  :label="this.$t('labels.board-local')"
                  :optionLabel="this.$label()"
              ></select-field>
              <checkbox-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-local-edit')"
                  :model="member" field="local_nomination" :label="this.$t('labels.nomination-committee')"></checkbox-field>
              <checkbox-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-local-edit')"
                  :model="member" field="local_revision" :label="this.$t('labels.auditor')" :optionLabel="this.$label()"></checkbox-field>
              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-local-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="true"
                  :options="memberFieldOptions.coordinators"
                  @updateMember="updateMember"
                  db_field="coordinator"
                  field="coordinator"
                  :label="this.$t('labels.coordinator-type')"
                  :optionLabel="this.$label()"
              ></select-field>
              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-local-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="false"
                  :options="memberFieldOptions.types"
                  @updateMember="updateMember"
                  db_field="member_type_id"
                  field="type"
                  :label="this.$t('labels.non-profit-employee')"
                  :optionLabel="this.$label()"
              ></select-field>

              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-local-edit')"
                  :model="member" field="work_percentage" :label="this.$t('labels.work-percentage')" type="number"></text-field>
              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-local-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="true"
                  :options="memberFieldOptions.support_types"
                  @updateMember="updateMember"
                  db_field="support"
                  field="support"
                  :label="this.$t('labels.supporters')"
                  :optionLabel="this.$label()"
              ></select-field>
                <select-field
                  v-if="this.user"
                  :editable="editThisMember() && $store.getters.permissions.includes('member-voluneteer-status-edit')"
                  :clearable="$store.getters.permissions.includes('member-voluneteer-status-clear')"
                  :model="member"
                  :multiple="false"
                  :options="memberFieldOptions.voluneteer_statuses"
                  @updateMember="updateMember"
                  db_field="member_volunteer_status_id"
                  field="volunteer"
                  :label="this.$t('labels.supporter-status')"
                  :optionLabel="this.$label()"
                  :save-manually="true"
                  @change="setVoluneteerStatus"
                  infoBox="Medlemsformulär - Uppdrag, Stödjarstatus"
                  :icon="true"
              ></select-field>
            </div>
            <div class="col-4 br-1">
              <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.regional-assignments') }}</h5>

              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-court-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="false"
                  :options="_.orderBy(memberFieldOptions.board_assignments, [$field('label')])"
                  @updateMember="updateMember"
                  db_field="regional_member_board_assignments_id"
                  field="regional_board_assignment"
                  :label="this.$t('labels.board-region')"
                  :optionLabel="this.$label()"
              ></select-field>
              <checkbox-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-region-edit')"
                  :model="member" field="regional_nomination" :label="this.$t('labels.nomination-committee')"></checkbox-field>
              <checkbox-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-region-edit')"
                  :model="member" field="regional_revision" :label="this.$t('labels.auditor')"></checkbox-field>
              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-region-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="false"
                  :options="memberFieldOptions.regions"
                  @updateMember="updateMember"
                  db_field="member_region_id"
                  field="region"
                  :label="this.$t('labels.region')"
              ></select-field>

              <!-- <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.active-in-following-courts') }}<info-box :field="'Medlemsformulär  - Uppdrag, Aktiv vid följande domstolar rubrik'" /></h5>
              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-court-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="true"
                  :options="memberFieldOptions.courts"
                  @updateMember="updateMember"
                  db_field="courts"
                  field="courts"
                  label=""
                  :disabled="true"
              ></select-field> -->
            </div>
            <div class="col-4">
              <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.national-assignments') }}</h5>
              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-national-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="false"
                  :options="_.orderBy(memberFieldOptions.board_assignments, [$field('label')])"
                  @updateMember="updateMember"
                  db_field="national_member_board_assignments_id"
                  field="national_board_assignment"
                  :label="this.$t('labels.federal-board')"
                  :optionLabel="this.$label()"
              ></select-field>
              <checkbox-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-national-edit')"
                  :model="member" field="national_nomination" :label="this.$t('labels.nomination-committee')"></checkbox-field>
              <checkbox-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-national-edit')"
                  :model="member" field="national_revision" :label="this.$t('labels.auditor')"></checkbox-field>
              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-national-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="false"
                  :options="memberFieldOptions.workgroups"
                  @updateMember="updateMember"
                  db_field="member_workgroup_id"
                  field="workgroup"
                  :label="this.$t('labels.working-group')"
                  :optionLabel="this.$label()"
              ></select-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-national-edit')"
                  :model="member" field="national_field" :label="this.$t('labels.area-of-responsibility')"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-national-edit')"
                  :model="member" field="national_period" :label="this.$t('labels.term-of-office')"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-national-edit')"
                  :model="member" field="national_electionyear" :label="this.$t('labels.elected-to-the-meeting')" type="number"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-assignment-national-edit')"
                  :model="member" field="federal_chancellery" :label="this.$t('labels.title')" type="text"></text-field>
            </div>
          </div>
          <div class="row" v-show="showDetailSection == 3">
            <div class="col-4 br-1">
              <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.support-certificate-diploma') }}<info-box :field="'Medlemsformulär  - Meriter, Stödjarbevis och diplom rubrik'" /></h5>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-merits-diploma-edit')"
                  :model="member" field="latest_diploma" :label="this.$t('labels.support-certificate') + ' A'" type="date"></text-field>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-merits-diploma-edit')"
                  :model="member" field="diploma_date" :label="this.$t('labels.support-certificate') + ' D'" type="date"></text-field>

              <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.latest-criminal-record') }}<info-box :field="'Medlemsformulär  - Meriter, Senaste utdrag ur belastningsregistret rubrik'" /></h5>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-merits-other-edit')"
                  :model="member" field="latest_police_record" :label="this.$t('labels.date')" type="date"></text-field>

              <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.vow-of-silence-signed') }}<info-box :field="'Medlemsformulär  - Meriter, Tystnadslöfte undertecknat rubrik'" /></h5>
              <text-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-merits-other-edit')"
                  :model="member" field="signed_confidentiality" :label="this.$t('labels.date')" type="date"></text-field>
            </div>
            <div class="col-4">
              <div v-for="group in member.qualification_groups" :key="group">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ group }}</h5>
                <div class="row mb-4" v-for="informationField in filteredQualifications(group)"
                     :key="informationField.id">
                  <div class="col-6 pt-2">
                    {{ $to(informationField,'label') }}
                  </div>
                  <div class="col-1 text-center pt-2">
                    <input
                        v-if="informationField.permission === null || $store.getters.permissions.includes(informationField.permission)"
                        type="checkbox"
                        @change="updateInformationField(member, informationField, $event)"
                        class="mt-1 form-check"
                        v-model="informationField.checked"
                    />
                    <div
                        v-if="informationField.permission !== null && !$store.getters.permissions.includes(informationField.permission)">
                      <i v-if="!informationField.checked" class="fas fa-times-circle text-danger"></i>
                      <i v-if="informationField.checked" class="fas fa-check-circle text-success"></i>
                    </div>
                  </div>

                  <div class="col-5">
                    <input

                        type="date"
                        class="form-control fixed-padding"
                        @blur="updateInformationField(member, informationField, $event)"
                        v-if="informationField.checked && (informationField.permission === null || $store.getters.permissions.includes(informationField.permission))"
                        v-model="informationField.date"
                    />

                    <div class="pt-2"
                         v-if="informationField.permission !== null && !$store.getters.permissions.includes(informationField.permission)">
                      {{ informationField.date }}
                    </div>
                  </div>
                </div>
                <div class="mt-5">&nbsp;</div>
              </div>
            </div>
            <div class="col-4">
              <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.language') }}<info-box :field="'Medlemsformulär  - Meriter, Språk rubrik'" /></h5>
              <select-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-merits-language-edit')"
                  :clearable="true"
                  :model="member"
                  :multiple="true"
                  :options="memberFieldOptions.languages"
                  @updateMember="updateMember"
                  db_field="language"
                  field="language"
                  label=""
                  :optionLabel="this.$label()"
              ></select-field>

              <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.other') }}</h5>

              <textarea-field
                  :editable="editThisMember() && $store.getters.permissions.includes('member-section-merits-other-edit')"
                  :model="member" field="special_skill" label="" type="text"></textarea-field>
            </div>
          </div>
          <div class="row" v-show="showDetailSection == 4">
            <div class="col-12">
              <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.delete-member') }}</h5>
              <button @click="deleteMember" class="btn btn-danger">{{ $t('labels.delete-member') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import TextField from '@/components/ticket/member/TextField';
import SelectField from '@/components/ticket/member/SelectField';
import CheckboxField from '@/components/ticket/member/CheckboxField';
import TextareaField from '@/components/ticket/member/TextareaField';
import InfoBox from '@/components/InfoBox';

export default {
  name: 'survey-page',
  components: {
    TextField,
    TextareaField,
    SelectField,
    CheckboxField,
    InfoBox,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      isLoading: true,
      member: {},
      showDetailSection: 1,
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    memberFieldOptions: function () {
      return this.$store.getters.memberOptions;
    },
  },

  beforeRouteEnter(to, from, next) {
    BojAPI.getMember(to.params.id)
        .then((response) => {
          next((vm) => {
            vm.setData(response.data)
            vm.$store.dispatch('getMemberOptions')
          });
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    this.member = null;
    BojAPI.getMember(to.params.id).then((response) => {
      this.setData(response.data);
      next();
    });
  },

  methods: {

    /**
     *
     */
    editThisMember() {

      if (this.$store.getters.permissions.includes('member-edit-applies-all')) {
        return true;
      }

      if (this.user.user.member.id === this.member.id && this.$store.getters.permissions.includes('member-edit-applies-only-own-user')) {
        return true;
      }

      return false;
    },


    /**
     *
     */
    filteredQualifications(group) {
      const fields = this.member.qualification_fields;
      return fields.filter((item) => item.group === group);
    },

    /**
     * Update Information Field.
     *
     * @param localoffice
     * @param field
     * @param event
     */
    updateInformationField(member, field, event) {
      if ((field.checked && field.foreign_id === null && field.date !== null) || field.foreign_id !== null) {
        BojAPI.updateMemberInformationField(member, field, event.target.value)
            .then((response) => {
              this.setData(response.data);
              this.$notify({
                group: 'foo',
                text: this.$t('labels.changes-saved'),
                type: 'success',
                duration: 3000,
              });
            })
            .catch((error) => {
              this.$notify({
                group: 'foo',
                text: this.$t('labels.no-permission'),
                type: 'warning',
                duration: 3000,
              });
            });
      }
    },

    updateMemberFieldApi(member, field, value) {
      BojAPI.updateMemberField(member, field, value)
        .then((response) => {
          this.setData(response.data);
            this.$notify({
              group: 'foo',
              text: this.$t('labels.changes-saved'),
              type: 'success',
              duration: 3000,
            });
            this.updateMember(response.data.data);
        })
        .catch((error) => {
          this.$notify({
            group: 'foo',
            text: this.$t('labels.no-permission'),
            type: 'warning',
            duration: 3000,
          });
        });
    },


    setVoluneteerStatus(field, value) {
      let message = null;
      let voluneteerStatusId = null;
      if (value) {
        let voluneteerStatus = this.$store.getters.memberOptions.voluneteer_statuses.find(element => element.id == value.id);
        voluneteerStatusId = value.id;
        message = this.$t('labels.voluneteer-'+ voluneteerStatus.tag +'-alert');
      } else {
        message = this.$t('labels.voluneteer-clear-alert');
      }
      
      if ( !message ) {
        this.updateMemberFieldApi(this.member, field, voluneteerStatusId);
        return;
      }
      
      this.$confirm({
        message: message,
        button: {
          yes: this.$t('labels.yes'),
          no: this.$t('labels.cancel'),
        },
        callback: (confirm) => {
          if(confirm) {
             this.updateMemberFieldApi(this.member, field, voluneteerStatusId);
          } else {
            // If cancel set previous value
            var volunteer = this.memberFieldOptions.voluneteer_statuses.find((x) => x.id == this.member.member_volunteer_status_id);
            this.member.volunteer = volunteer;
          }
        },
      }); 
      setTimeout(() => { 
        document.querySelector('.vc-container').style.width = '775px'; 

      }, 10);
    },


    /**
     * Deletes a member
     *
     * Redirect to members-page after 2 seconds,
     * allowing the notification to be shown.
     */
    deleteMember() {
      if (confirm('Är du säker?')) {
        BojAPI.deleteMember(this.member.id)
            .then((response) => {
              let self = this;
              this.$notify({
                group: 'foo',
                text: this.$t('labels.member-deleted'),
                type: 'success',
                duration: 3000,
              });
              setTimeout(function () {
                self.$router.push({name: 'members.members'});
              }, 2000);
            })
            .catch((error) => {
              this.$notify({
                group: 'foo',
                text: this.$t('labels.no-permission-deleting-member'),
                type: 'warning',
                duration: 3000,
              });
            });
      }
    },

    /**
     * Update member object from a child component
     *
     * @param member
     */
    updateMember(member) {
      this.$emit('updateMember', member);
    },

    /**
     * Toggles current form section
     *
     * @param section
     */
    setShowDetailSection(section) {
      this.showDetailSection = section;
    },

    setData(payload) {
      this.member = payload.data;
      this.isLoading = false;
    },
  },
};
</script>
