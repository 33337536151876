<template>
  <div class="form-group row">

    <label :for="'member' + field" class="col-sm-4 col-form-label">
      {{ label }}
      <info-box :field="infoBox" />
    </label>
    <div v-if="rows === 1" class="position-relative"
         :class="type !== 'email' && type !== 'tel' ? 'col-sm-8' : 'col-sm-6 pr-0'">
      <input
          v-if="editable"
          autocomplete="chrome-off"
          list="autocompleteOff"
          :type="type"
          v-on:keyup.enter="$event.target.blur()"
          :value="model[field]"
          @blur="updateTextField(model, field, $event)"
          class="form-control"
          :id="'member' + field"
      />
      <div class="pt-2" v-if="!editable"
           v-html="model[field] !== '' ? model[field] : '<span class=\'text-muted\'>-</span>'"></div>
    </div>
    <div class="col-sm-2" v-if="type == 'email'">
      <a tabindex="-1" :href="'mailto:' + model[field]" class="h-100 btn w-100 btn-light"><i
          class="mt-2 fas fa-envelope opacity-40"></i></a>
    </div>
    <div class="col-sm-2" v-if="type == 'tel'">
      <a tabindex="-1" :href="'tel:' + model[field]" class="h-100 btn w-100 btn-light"><i
          class="mt-2 fas fa-phone opacity-40"></i></a>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import InfoBox from '@/components/InfoBox';

export default {
  name: 'loader',
  props: {
    model: Object,
    field: String,
    infoBox: String,
    type: {
      type: String,
      default: 'text',
    },
    label: String,
    editable: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 1,
    },
  },
  components: {
    InfoBox
  },
  methods: {
    updateTextField: function (item, field, event) {
      if (item[field] != event.target.value) {
        this.model[field] = event.target.value;
        BojAPI.updateMemberField(this.model, field, event.target.value)
            .then(() => {
              this.$emit('updateMember', this.model);
              this.$notify({
                group: 'foo',
                text: this.$t('labels.changes-saved'),
                type: 'success',
                duration: 3000,
              });
            })
            .catch((error) => {
              this.$notify({
                group: 'foo',
                text: this.$t('labels.no-permission'),
                type: 'warning',
                duration: 3000,
              });
            });
      }
    },
  },
};
</script>
