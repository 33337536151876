<template>
  <div class="form-group row">
    <label for="ticket_age" class="col-sm-12 col-form-label" v-if="label.length !== 0">{{ label }}</label>
    <div class="col-12">
      <textarea-autosize
          :min-height="130"
          :max-height="350"
          :type="type"
          :value="model[field]"
          @blur.native="updateTextField(model, field, $event)"
          rows="4"
          class="form-control"
          :id="'member' + field"
          v-if="editable"
      ></textarea-autosize>
      <div v-if="!editable" v-html="model[field] !== '' ? model[field] : '<span class=\'text-muted\'>-</span>'"></div>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';

export default {
  name: 'loader',
  props: {
    model: Object,
    field: String,
    type: {
      type: String,
      default: 'text',
    },
    label: String,
    editable: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 1,
    },
  },

  methods: {
    updateTextField: function (item, field, event) {
      if (item[field] != event.target.value) {
        this.model[field] = event.target.value;
        BojAPI.updateMemberField(this.model, field, event.target.value)
            .then(() => {
              this.$notify({
                group: 'foo',
                text: this.$t('labels.changes-saved'),
                type: 'success',
                duration: 3000,
              });
            })
            .catch((error) => {
              this.$notify({
                group: 'foo',
                text: this.$t('labels.no-permission'),
                type: 'warning',
                duration: 3000,
              });
            });
      }
    },
  },
};
</script>
