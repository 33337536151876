<template>
  <div class="form-group row">
    <label :for="'member' + field" class="col-sm-4 col-form-label">{{ label }}</label>
    <div class="col-sm-2">
      <div class="pl-4 pt-2">
        <input v-if="editable" type="checkbox" class="form-check-input" v-model="model[field]"
               @change="updateTicketField(model, field)" :id="'member' + field"/>
      </div>
      <div class="" v-if="!editable">
        <i v-if="!model[field]" class="fas fa-times-circle text-danger"></i>
        <i v-if="model[field]" class="fas fa-check-circle text-success"></i>
      </div>
    </div>
  </div>
</template>
<script>
import BojAPI from '@/api/boj';

export default {
  name: 'loader',
  props: {
    model: Object,
    field: String,
    type: String,
    label: String,
    editable: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    updateTicketField(model, field) {
      var value = model[field];

      BojAPI.updateMemberField(this.model, field, value)
          .then(() => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.changes-saved'),
              type: 'success',
              duration: 3000,
            });
            this.reRender = true;
          })
          .catch((error) => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.no-permission'),
              type: 'warning',
              duration: 3000,
            });
          });
    },
  },
};
</script>
